<template>
  <div class="messages">
    <h2>Сообщения</h2>
  </div>
</template>

<script>
export default {
  name: 'Messages',
};
</script>
